body {
  background-color: var(--color-theme-background-primary);
  position: relative;
}

.icons {
  font-family: Material Icons Outlined;
  font-style: normal;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.row.pad0 {
  .col-xs-12 {
    padding: 0px;
  }
}

.config {
  &__form {
    width: 320px;
    position: fixed;
    left: -320px;
    bottom: 0;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    transition: all 0.4s ease-in-out;
    z-index: 9;
    color: #101010;

    &.active {
      left: 0;
    }

    h5 {
      font-size: 18px;
      margin-top: 20px;
    }

    select {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;

    input[type="color"] {
      width: 13px;
      height: 13px;
    }

    label {
      font-weight: 600;
    }
  }

  &__save {
    font-size: 14px;
    height: 25px;
    line-height: 25px;
    background-color: var(--color-theme-green);
    border-color: var(--color-theme-green);
    margin-top: 20px;
    display: inline-block;

    &:hover {
      color: var(---color-theme-font-primary);
    }
  }

  &__reset {
    font-size: 14px;
    height: 25px;
    line-height: 25px;
    background-color: var(--color-theme-red);
    border-color: var(--color-theme-red);
    margin-top: 20px;
    display: inline-block;

    &:hover {
      color: var(---color-theme-font-primary);
    }
  }
}

.contactform {
  &__notices {
    position: relative;
  }

  &__success,
  &__error,
  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
    z-index: 3;
    opacity: 0;
    display: none;
    @extend .transEase;

    &.active {
      display: block;
      opacity: 1;
    }

    .spin {
      width: 100px;
      height: 100px;
      border: 5px solid;
      margin: 50px auto;
      border-radius: 50%;
      border-color: var(--color-theme-primary) transparent;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    img {
      margin-bottom: 20px;
      width: 130px;
      height: 130px;
      object-fit: contain;
    }

    .social {
      display: flex;
      gap: 15px;
      justify-content: center;
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }

  &__form {
    &.hidden {
      display: none;
    }

    &.half {
      opacity: 0.2;
    }
  }
}

.excerpt-hidden {
  height: 150px;
  overflow: hidden;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(to bottom, #fff6 0, #fff 100%);
  }
}

.js-show-more {
  color: var(--color-primary) !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  width: fit-content;
}

[data-module="text"] ol {
  list-style-type: decimal;
  padding-left: 2rem;
}
