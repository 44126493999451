.button {
  background-color: var(--color-theme-primary);
  font-size: 14px;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  letter-spacing: 2px;
  color: var(--color-theme-white);
  border: 1px solid var(--color-theme-primary);
  font-weight: 500;
  display: flex;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  @extend .transEase;
  &.bold {
    font-weight: 700;
  }
  &:hover {
    background-color: transparent;
    color: var(--color-theme-primary);
  }
  &.green {
    background-color: var(--color-theme-green);
    border: 2px solid var(--color-theme-green);
  }
  &.secondary {
    background-color: var(--color-theme-secondary);
    border: 2px solid var(--color-theme-secondary);
    &:hover {
      background-color: var(--color-theme-white);
      color: var(--color-theme-secondary);
    }
  }
  &Outline {
    @extend .button;
    color: var(--color-theme-primary);
    background-color: transparent;
    &:hover {
      background-color: var(--color-theme-primary);
      color: var(--color-theme-white);
    }
    &--white {
      color: var(--color-theme-white);
      background-color: transparent;
      border-color: var(--color-theme-white);
      &:hover {
        border-color: var(--color-theme-secondary);
        background-color: var(--color-theme-secondary);
        color: var(--color-theme-white);
      }
    }
  }
  &Second {
    @extend .button;
    color: var(--color-theme-white);
    background-color: var(--color-theme-secondary);
    border-color: var(--color-theme-secondary);
    &:hover {
      background-color: var(--color-theme-primary);
    border-color: var(--color-theme-primary);

      color: var(--color-theme-white);
    }
  }
  &--center {
    margin: 0 auto;
    display: block;
    @extend .button;
  }
  &--fit {
    width: fit-content;
    @extend .button;
  }
  .icons {
    font-family: Material Icons;
    font-style: normal;
    font-size: 16px;
  }
}
.buttonArrow {
  background: var(--color-theme-primary);
  color: var(--color-theme-white);
  width: 40px;
  height: 40px;
  font-size: 16px;
  border-radius: 50%;
  font-family: Material Icons;
  font-style: normal;
  line-height: 40px;
  text-align: center;
  z-index: 99;
  &.swiper-button-disabled {
    visibility: hidden;
  }
  &--left {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &--right {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
