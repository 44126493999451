@media (max-width: 991px) {
	body {
		.hidden-tb {
			display: none !important;
		}

		.header {
			&__logo {
				position: relative;
				left: 0;
				img {
					height: 100%;
					margin-left: 0px;
					transition: all .2s ease-in;
				}
			}

			.menu {
				&__info {
					display: none;
				}
			}
		}

		.text {
			&__content {
				padding: 0px 10px 20px 10px;

				iframe {
					height: 400px;
				}
			}
		}

		.iconBox {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				display: none;
			}

			.icons {
				&__image {
					&--title {
						position: relative;

						.background {
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							height: 75px;
							background-color: rgba(0, 0, 0, 0.2);
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.hidden-sm {
		display: none !important;
	}

	.textwithphoto {
		&__image {
			margin: 15px;
		}
	}
}

@media (max-width: 480px) {
	.hidden-xs {
		display: none !important;
	}
}

@media (max-width: 380px) {
	.hidden-xxs {
		display: none !important;
	}
}