.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10609;
	display: none;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	background-color: rgba(#fff, 0.95);

	&Content {
		top: 50;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		max-width: 820px;
		min-width: 820px;
		@media (max-width: 991px) {
			max-width: auto;
			min-width: auto;
			width: 100%;
			padding: 0 20px;
			height: calc(100vh - 150px);
		}
		@media (max-width: 480px) {
			padding: 0;
		}
		.close.icon {
			position: absolute;
			right: 0;
			top: 0;
			font-size: 30px;
			cursor: pointer;
			@media (max-width: 991px) {
				right: 30px;
			}
		}
		.form__number {
			width: 220px;
			background: #fff;
			border-radius: 30px;
			padding: 12px 20px;
			border: 1px solid var(--color-theme-light-grey);
			display: flex;
			justify-content: space-between;
			text-align: center;
			margin-top: 10px;
			color: var(--color-theme-primary);
			font-size: 16px;
			font-weight: 600;
			span {
				min-width: 30px;
				height: 30px;
				line-height: 30px;
				cursor: pointer;
			}
			input {
				width: 100px;
			}
			.add-age{
				width: 100px
			}
			@media (max-width: 768px) {
				width: auto;
				padding: 5px 15px;
				font-size: 14px;
				input {
					width: 50px;
				}
			}
		}
		.showAlert{
			display: flex;
			justify-content: center;
			width: 100%;
			color: var(--color-theme-alert-danger);
			background-color: (--color-theme-secondary);
			font-size: 14px;
			font-weight: 600;
			margin-top: 10px;
			max-height: 0;
			overflow: hidden;
   			animation: showKidsAlert 20s ease-out forwards;
		}
		@keyframes showKidsAlert {
			0% {
				max-height: 0;
			}
			100% {
				max-height: 500px;
			}
		}
	}

	&.active {
		display: block;
	}

	/* Kalendarz  */
	.calendarInput {
		&__wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;
			margin: 20px 0;

			.swiper-slide {
				display: flex;
				justify-content: center;
				width: 370px;
				@media (max-width: 768px) {
					width: auto;
					margin: 0 auto;
				}
			}

			#btnNext {
				position: absolute;
				right: 0;
				top: 0px;
				z-index: 2;
				padding: 5px;

				i.icon {
					font-size: 28px;
					margin: 0;
				}
			}

			#btnPrev {
				position: absolute;
				left: 0;
				top: 0px;
				z-index: 2;
				padding: 5px;

				i.icon {
					font-size: 28px;
					margin: 0;
				}
			}
		}

		&__container {
			display: flex;
			justify-content: space-between;
		}

		&__table {
			width: 370px;
			text-align: center;
			border-spacing: 5px;
			border-collapse: separate;
			border: 0px;
			@media (max-width: 768px) {
				width: auto;
				margin: 0 auto;
			}
			thead td {
				border: 0px;
				font-size: 16px;
				font-weight: 400;
				background-color: transparent;
				@media (max-width: 768px) {
					font-size: 14px;
				}
			}

			tbody {
				width: 100%;
			}

			tr {
				background-color: transparent;
			}

			td {
				padding: 0;
				position: relative;
				height: 45px;
				width: 45px;
				line-height: 45px;
				font-size: 16px;
				font-weight: 500;
				z-index: 1;
				border-radius: 50%;
				background-color: #fff;
				color: var(--color-theme-primary);
				border: 1px solid var(--color-theme-light-grey);
				cursor: pointer;
				@media (max-width: 768px) {
					height: 35px;
					width: 35px;
					line-height: 35px;
					font-size: 14px;
				}

				&.normal {
					&:hover {
						background-color: var(--color-theme-light-grey);
					}
				}

				&.not-current {
					background: #f0f0f0;
					color: #a5d5f5;
					opacity: 0;
					visibility: hidden;
				}

				&.today {
					background-color: var(--color-theme-green);
					color: #fff;
				}

				&.select {
					background-color: var(--color-theme-primary);
					color: #fff;
					border: 1px solid transparent;

					&:after {
						content: "";
						width: 50px;
						height: 47px;
						background: var(--color-theme-primary);
						position: absolute;
						top: -1px;
						right: -30px;
						z-index: -1;
						@media (max-width: 768px) {
							width: 45px;
							height: 37px;
							right: -25px;
						}
					}

					&:hover {
						background-color: var(--color-theme-primary);

						&::after {
							content: unset;
						}
					}

					&:last-of-type,
					&.departure {
						&:after {
							content: unset;
						}
					}
				}
			}

			.days {
				opacity: 0.4;
				font-size: 14px;

				td {
					border: 0px;
					padding: 10px 0;
					height: 10px;
					line-height: 10px;
				}
			}
		}
	}
	.summaryCalendar{
		text-align: center;
		font-size: 28px;
		color: var(--color-theme-primary);
	}
	.countPerson {
		display: flex;
		align-items: center;
		margin: 20px 0;
		&_item {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 16px;
			font-weight: 600;
			width: 50%;
			flex-basis: 50%;
			@media (max-width: 768px) {
				font-size: 14px;
			}
		}
	}
	.countKids {
		display: flex;
		flex-wrap: wrap;
		gap: 0 20px;
		&Age {
			display: flex;
			width: 200px;
			align-items: center;
			gap: 10px;
			position: relative;
			padding-top: 30px;
			border: 0px;
			background: transparent;
			width: fit-content;
			label {
				position: absolute;
				top: 5px;
				font-size: 13px;
			}
			.button {
				width: 25px;
				height: 25px;
				background-color: transparent;
				padding: 0;
				color: var(--color-theme-alert-danger);
				border: 0px;
			}
		}
	}
	.leaflet-container a.button {
		color: #fff;
		&:hover {
			color: var(--color-theme-primary);
		}
	}
	.button {
		width: 200px;
		margin: 0 auto;
	}
}
