p {
    font-size: 16px;
}

h3 {
    font-size: 22px;
    font-weight: normal;
}

h2.title{
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    @media (max-width:768px) {
        text-align: left;
    }
}

.hidden {
    display: none !important;
}

.transEase {
    transition: all .2s ease-in-out;
}


.iconsMaterial,
.icon {
    font-family: Material Icons;
    font-style: normal;
}

.filterPrimary {
    // Filter brany ze strony https://codepen.io/sosuke/pen/Pjoqqp
    filter: invert(40%) sepia(66%) saturate(883%) hue-rotate(167deg) brightness(97%) contrast(87%);
}

.filterSecondary {
    // Filter brany ze strony https://codepen.io/sosuke/pen/Pjoqqp
    filter: invert(66%) sepia(100%) saturate(526%) hue-rotate(342deg) brightness(110%) contrast(98%);
}

a[href="https://www.noclegi.com"]{
    text-decoration: underline !important;
}